<template>
    <div class="div-card-type">
        <img v-if="item.typeEntity == 'B'" title="Basic" src="@/assets/images/icons/basic.png" alt="sello"
            class="superior-derecha" />

        <img v-else-if="item.typeEntity == 'P'" title="Premium" src="@/assets/images/icons/premium.png" alt="sello"
            class="superior-derecha" />

        <img v-else title="Member" src="@/assets/images/icons/member.png" alt="sello" class="superior-derecha" />

        <img v-if="item.excellence === 1" src="@/assets/images/icons/escudo_coepris.f8331f99.png" alt="sello1"
            class="superior-derecha-segunda" title="Excellence" />

        <img v-if="item.coparmex === 1" src="@/assets/images/icons/coparmex.png" alt="sello1"
            class="superior-derecha-tercera" title="Coparmex" />

        <img v-if="item.repsabi === 1" src="@/assets/images/icons/repssabi.png" alt="sello1"
            class="superior-derecha-cuarta" title="Repssabi" />
    </div>
</template>
<script>
export default {
    name: "TypeEntityView",
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>
<style>
.div-card-type {
    position: absolute;
    top: 0;
    right: 0;
}
.superior-derecha {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 80px;
    height: 80px;
}

.superior-derecha-segunda {
    position: absolute;
    top: 60px;
    right: -7px;
    width: 80px;
    height: 80px;
}

.superior-derecha-tercera {
    position: absolute;
    top: 150px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50%;
}

.superior-derecha-cuarta {
    position: absolute;
    top: 220px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50%;
}
</style>