<template>
  <div>
    <clinics-component />
    <footer-component />
  </div>
</template>

<script>
import ClinicsComponent from "@/components/hospitals-clinics/ClinicsComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "Clinics",
  title: "Top 10 Clinics | Baja California Health Tourism",
  components: { FooterComponent, ClinicsComponent },
  mounted() {
    changeInformationSEO();
  }
};
</script>

<style scoped></style>
